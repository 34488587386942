'use client'

import Image from 'next/image'
import Slider from 'react-slick'

export function BannerComponent() {
  // __RENDER
  return (
    <div className='ui--home-banner'>
      <div className='container'>
        <Slider autoplay infinite initialSlide={0} speed={800} slidesToShow={1} slidesToScroll={1}>
          <div className='slide' key='.slide-1'>
            <Image
              alt=''
              className='size-full object-contain object-center'
              src='/static/images/banner-1.png'
              width={720}
              height={400}
              quality={100}
              key={1}
            />
          </div>

          <div className='slide' key='.slide-2'>
            <Image
              alt=''
              className='size-full object-contain object-center'
              src='/static/images/banner-1.png'
              width={720}
              height={400}
              quality={100}
              key={1}
            />
          </div>

          <div className='slide' key='.slide-3'>
            <Image
              alt=''
              className='size-full object-contain object-center'
              src='/static/images/banner-1.png'
              width={720}
              height={400}
              quality={100}
              key={1}
            />
          </div>
        </Slider>
      </div>
    </div>
  )
}
